import React, { useState, useEffect } from "react";
import OwlCarousel from "react-owl-carousel";
import DataContent from "./DataContent";
import { Helmet } from "react-helmet";

export default function Service() {
  const Site_Url = "https://namechangeindrivinglicense.com/";
  const BASE_URL = Site_Url + "admin/API/";

  //website Setting API
  const [webSetting, setwebSetting] = useState([]);
  useEffect(() => {
    const webSettingData = async () => {
      const response = await fetch(`${BASE_URL}website_settingAPI.php`);
      const jsonData = await response.json();
      setwebSetting(jsonData);
    };
    webSettingData();
  }, [BASE_URL]);

  //service API
  const [servicedata, setservicedata] = useState([]);
  useEffect(() => {
    const AllServices = async () => {
      const response = await fetch(`${BASE_URL}serviceAPI.php`);
      const jsonData = await response.json();
      setservicedata(jsonData);
    };
    AllServices();
  }, [BASE_URL]);

  // Content Api
  const [contentdata, setcontentdata] = useState([]);
  useEffect(() => {
    const AllContent = async () => {
      const response = await fetch(`${BASE_URL}contentAPI.php`);
      const jsonData = await response.json();
      setcontentdata(jsonData);
    };
    AllContent();
  }, [BASE_URL]);

  // Banner Api
  const [slider, setbannerdata] = useState([]);
  useEffect(() => {
    const AllBanner = async () => {
      const response = await fetch(`${BASE_URL}bannerAPI.php`);
      const jsonData = await response.json();
      setbannerdata(jsonData);
    };
    AllBanner();
  }, [BASE_URL]);

  //whychoose API
  const [whyChoosedata, setwhyChoosedata] = useState([]);
  useEffect(() => {
    const AllwhyChoose = async () => {
      const response = await fetch(`${BASE_URL}whychooseAPI.php`);
      const jsonData = await response.json();
      setwhyChoosedata(jsonData);
    };
    AllwhyChoose();
  }, [BASE_URL]);

  //Page Meta Tags
  const [PagemetaTags, setPagemetaTags] = useState([]);
  useEffect(() => {
    const MetaTagsData = async () => {
      const response = await fetch(`${BASE_URL}pagemetaTags.php`);
      const jsonData = await response.json();
      setPagemetaTags(jsonData);
    };
    MetaTagsData();
  }, [BASE_URL]);

  const options = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    dots: true,
    loop: true,
    autoplay: true,
    navText: ["Prev", "Next"],
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 2,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>{PagemetaTags.ServiceMetatitle}</title>
        <meta name="keywords" content={PagemetaTags.ServiceMetaKeyword} />
        <meta name="description" content={PagemetaTags.ServiceMetaDesc} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* Banner Start */}
      <section>
        <div className="banner">
          <img
            src={slider.service_banner}
            alt={slider.service_banner_alt}
            title={slider.service_banner_title}
          />
        </div>
      </section>
      {/* Banner End */}

      {/*Service Start */}
      <section className="service_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="service_title ">
                <h2>{webSetting.service_title}</h2>
              </div>

              <OwlCarousel className="owl-theme" {...options}>
                {servicedata.map((value, index) => (
                  <div key={index} className="item box_item service_icn">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: value.icon,
                      }}
                    ></div>
                    <div className="service_tx">
                      <h4>
                        <a href="/service">{value.name}</a>
                      </h4>
                    </div>
                  </div>
                ) )}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*Service End */}

      {/* Content-6 Section Start */}
      {contentdata.title6 && (
        <DataContent
          title={contentdata.title6}
          desc={contentdata.desciption6}
        />
      )}
      {/* Content-1 Section End */}

      <section className="consult_sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="name_consult">
                <h4>{webSetting.whyChoose_title}</h4>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="consult_bx">
                <img src={whyChoosedata.stap1_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap1_title}</p>
                  <h6>{whyChoosedata.stap1_tagline}</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="consult_bx">
                <img src={whyChoosedata.stap2_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap2_title}</p>
                  <h6>{whyChoosedata.stap2_tagline}</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="consult_bx">
                <img src={whyChoosedata.stap3_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap3_title}</p>
                  <h6>{whyChoosedata.stap3_tagline}</h6>
                </div>
              </div>
            </div>

            <div className="col-md-3 col-6">
              <div className="consult_bx">
                <img src={whyChoosedata.stap4_img} alt="" title="" />
                <div className="consult_bx_tx">
                  <p>{whyChoosedata.stap4_title}</p>
                  <h6>{whyChoosedata.stap4_tagline}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
